import { Button } from '@/components/ui/button';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const QuestionsPage = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const title = user ? 'You have no questions' : 'Sign in to view questions';
  const newQuestionHandler = () => {
    if (!user) {
      navigate('/auth/sign-in');
      return null;
    } else {
      navigate(`new`);
    }
  };
  return (
    <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">Questions</h1>
      </div>
      <div className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm">
        <div className="flex flex-col items-center gap-1 text-center">
          <h3 className="text-2xl font-bold tracking-tight">{title}</h3>
          <p className="text-sm text-muted-foreground">
            Start screening candidates by adding questions
          </p>
          <Button className="mt-4" onClick={newQuestionHandler}>
            New Question
          </Button>
        </div>
      </div>
    </main>
  );
};
