import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { AdminApp } from './apps/admin/AdminApp';
import AdminStructure from './apps/admin/structure';
import SettingsStructure from './apps/settings/structure';

import { SettingsApp } from './apps/settings/SettingsApp';
import { AuthProvider } from './components/auth/Auth';
import { Protected } from './components/auth/Protected';
import { SignIn } from './components/auth/SignIn';
import { SignOut } from './components/auth/SignOut';
import './index.css';
import { NewQuestion } from './apps/admin/pages/NewQuestion';
import NotFound from './components/NotFound';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AdminApp />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Navigate replace to={AdminStructure.Questions.path} />,
      },
      {
        path: AdminStructure.Questions.path,
        element: <AdminStructure.Questions.element />,
      },
      {
        path: `${AdminStructure.Questions.path}/new`,
        element: <Protected component={NewQuestion} />,
      },
      {
        path: AdminStructure.Sessions.path,
        element: <Protected component={AdminStructure.Sessions.element} />,
      },
    ],
  },
  {
    path: '/auth',
    children: [
      {
        index: true,
        element: <Navigate replace to="sign-in" />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-out',
        element: <SignOut />,
      },
    ],
  },
  {
    path: '/settings',
    element: <Protected component={SettingsApp} />,
    children: [
      {
        index: true,
        element: <Navigate replace to={SettingsStructure.Users.path} />,
      },
      {
        path: SettingsStructure.Users.path,
        element: <SettingsStructure.Users.element />,
      },
      {
        path: SettingsStructure.Billing.path,
        element: <SettingsStructure.Billing.element />,
      },
    ],
  },
]);

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
);
