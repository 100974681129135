import { BoomBox, Home } from 'lucide-react';
import { QuestionsPage } from './pages/Questions';
import { SessionsPage } from './pages/Sessions';

export default {
  Questions: {
    position: 0,
    path: '/questions',
    icon: Home,
    label: 'Questions',
    element: QuestionsPage,
  },
  Sessions: {
    position: 1,
    path: '/sessions',
    icon: BoomBox,
    label: 'Sessions',
    element: SessionsPage,
  },
};
