import { Drama, Menu } from 'lucide-react';
import { Link, NavLink } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';

import { Sheet, SheetContent, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { UserMenu } from '@/components/UserMenu';
import { Outlet } from 'react-router';
import AdminStructure from './structure';
import { useAuth0 } from '@auth0/auth0-react';
import { FullScreenSpinner } from '@/components/FullScreenSpinner';

const title = 'Code Screening';

export function AdminApp() {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <FullScreenSpinner />;
  }

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
            <Link to="/" className="flex items-center gap-2 font-semibold">
              <Drama className="h-6 w-6" />
              <span className="">{title}</span>
            </Link>
          </div>
          <div className="flex-1">
            <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
              {Object.entries(AdminStructure).map(([key, value]) => (
                <NavLink
                  key={key}
                  to={value.path}
                  className={({ isActive }) =>
                    [
                      'flex',
                      'items-center',
                      'gap-3',
                      'rounded-lg',
                      'px-3',
                      'py-2',
                      isActive ? 'text-foreground' : 'text-muted-foreground',
                      isActive ? 'bg-muted' : '',
                      'transition-all',
                      'hover:text-primary',
                    ].join(' ')
                  }
                >
                  <value.icon className="h-4 w-4" />
                  {value.label}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className="mt-auto p-4">
            <Card>
              <CardHeader className="p-2 pt-0 md:p-4">
                <CardTitle>Upgrade to Pro</CardTitle>
                <CardDescription>
                  Unlock all features and get unlimited access to our support team.
                </CardDescription>
              </CardHeader>
              <CardContent className="p-2 pt-0 md:p-4 md:pt-0">
                <Button size="sm" className="w-full">
                  Upgrade
                </Button>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6">
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="outline" size="icon" className="shrink-0 md:hidden">
                <Menu className="h-5 w-5" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="flex flex-col">
              <nav className="grid gap-2 text-lg font-medium">
                <Link to="/" className="flex items-center px-3 py-2 text-lg font-semibold">
                  <Drama className="h-6 w-6" />
                  <SheetTitle />
                  <span className="sr-only">{title}</span>
                </Link>
                {Object.entries(AdminStructure).map(([key, value]) => (
                  <NavLink
                    key={key}
                    to={value.path}
                    className={({ isActive }) =>
                      [
                        'flex',
                        'items-center',
                        'gap-4',
                        'rounded-xl',
                        'px-3',
                        'py-2',
                        'hover:text-foreground',
                        isActive ? 'text-foreground' : 'text-muted-foreground',
                        isActive ? 'bg-muted' : '',
                      ].join(' ')
                    }
                  >
                    <value.icon className="h-5 w-5" />
                    {value.label}
                  </NavLink>
                ))}
              </nav>
              <div className="mt-auto">
                <Card>
                  <CardHeader>
                    <CardTitle>Upgrade to Pro</CardTitle>
                    <CardDescription>
                      Unlock all features and get unlimited access to our support team.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Button size="sm" className="w-full">
                      Upgrade
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </SheetContent>
          </Sheet>
          <div className="w-full flex-1"></div>
          <UserMenu />
        </header>
        <Outlet />
      </div>
    </div>
  );
}
