import { Drama, ArrowLeft } from 'lucide-react';
import { Link, NavLink, Outlet } from 'react-router-dom';

import { UserMenu } from '@/components/UserMenu';
import SettingsStructure from './structure';

export function SettingsApp() {
  return (
    <div className="flex min-h-screen w-full flex-col">
      <header className="sticky top-0 flex h-14 items-center justify-between gap-4 bg-muted/40 px-4 lg:h-[60px] lg:px-6">
        <Link to="/" className="flex items-center gap-2 font-semibold">
          <Drama className="h-6 w-6" />
          <span className="">Code Screening</span>
        </Link>
        <UserMenu />
      </header>
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto flex items-center w-full max-w-6xl gap-2">
          <NavLink to="/" className="hover:text-muted-foreground">
            <ArrowLeft />
          </NavLink>
          <h1 className="text-3xl font-semibold">Settings</h1>
        </div>
        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <nav className="grid gap-4 text-sm text-muted-foreground">
            {Object.entries(SettingsStructure).map(([key, value]) => (
              <NavLink
                key={key}
                to={value.path}
                className={({ isActive }) =>
                  [
                    isActive ? 'font-semibold' : '',
                    isActive ? 'text-primary' : '',
                    'hover:text-primary',
                  ].join(' ')
                }
              >
                {value.label}
              </NavLink>
            ))}
          </nav>
          <div className="grid gap-6">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}
